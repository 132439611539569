import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Seo from '../components/seo';
import Layout from '../components/layout/layout';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Page, PageContent, PageTitle } from '../components/global/page';
import Breadcrumbs from '../components/global/breadcrumbs';
import { Row, navigateTo, Tag } from '../components/global/utils';
import { Button } from '../components/global/button';

const Description = styled.div`
  font-family: 'Raleway';
  margin: 1.5rem 0;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 150%;
  color: #000;
`;

const Img = styled.img`
  margin-top: 4rem;
  object-fit: cover;
  border-radius: 10px;
  max-width: 1180px;
  max-height: 600px;
`;

const WatchMoreButton = styled(Button)`
  margin-top: 2rem;
  width: 380px;
  @media only screen and (max-width: 700px) {
    margin-top: 1rem;
    width: calc(100vw - 32px);
  }
`;

export default function InterviewPage({ pageContext }) {
  const { t } = useTranslation();

  const breadcrumbsPath = [
    { name: t('news.main.header'), path: '/news/' },
    {
      name: t('interview-page.breadcrumbs.interviews'),
      path: '/news/interviews'
    },
    { name: t('interview-page.breadcrumbs.interview'), path: '' }
  ];
  return (
    <Layout withHeader={false}>
      <Seo
        title={pageContext.title}
        description={pageContext.description.description}
        ogImage={pageContext.thumbnail.file.url}
      />
      <Page className="interviewPage">
        <PageContent>
          <Breadcrumbs path={breadcrumbsPath}></Breadcrumbs>
          <PageTitle>{pageContext.title}</PageTitle>
          <Description>
            {pageContext.content
              ? renderRichText(pageContext.content)
              : pageContext.description.description}
          </Description>
          <Row shouldWrap={true}>
            {(pageContext?.type?.split(', ') ?? [])?.map((tag) => {
              return <Tag key={tag}>{tag}</Tag>;
            })}
          </Row>
          <Img
            src={pageContext.thumbnail.file.url}
            alt={pageContext.thumbnail.title}
          />
          <Row className="justify-center">
            <WatchMoreButton
              className="solid"
              onClick={() => navigateTo(pageContext.link, '_blank')}>
              {t('interview-page.watch-more')}
            </WatchMoreButton>
          </Row>
        </PageContent>
      </Page>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
